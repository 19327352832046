(function () {
    'use strict';

    angular
        .module('überliste')
        .component('mainHeader', {
            templateUrl: 'template/component/header.html',
            controller: HeaderController,
            bindings: {
                categoriesCatalog: '<',
                currentUserEmail: '<'
            }
        });

    HeaderController.$inject = [
        '$log',
        'NavigationService',
        'DialogService',
        'LoginService',
        'Session'];

    function HeaderController($log, navigationService, dialogService, loginService, session) {
        var ctrl = this;

        ctrl.openLoginDialog = dialogService.openLoginDialog;
        ctrl.onSettingSelect = function(setting){ setting.click(); };

        ctrl.settings = [
            {
                'name': 'user_settings_menu.user_products_title',
                'click': function () { dialogService.openProductsDialog(ctrl.categoriesCatalog); }
            },
            {
                'name': 'user_settings_menu.account_settings_title',
                'click': function () { dialogService.openSettingsDialog(); }
            },
            {
                'name': 'user_settings_menu.logout_title',
                'separator': true,
                'click': function () {
                    session.destroy();
                    loginService.logout(navigationService.navigateToHome, function () {
                        $log.log('Logout failed.');
                    });
                }
            }
        ];
    }
})();
