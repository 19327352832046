(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('ShareListController', ShareListController);

    ShareListController.$inject = ['$scope', '$location', 'NotificationService', 'ProductListService'];

    function ShareListController($scope, $location, notificationService, productListService) {
        init();
        
        $scope.cancel = cancel;
        
        notificationService.setControllerScope($scope);
        notificationService.resetNotification();
        
		$scope.$on('ngDialog.opened', function () {
            productListService
                .share($scope.activeList.id)
                .then(successCallback)
                .catch(errorCallback);
		});

        function init() {
			$scope.joinListUrl = '';
			$scope.listName = $scope.activeList.name;
        }

        function cancel() {
            $scope.closeThisDialog();
        }

		var successCallback = function (response) {
			$scope.joinListUrl = $location.absUrl().replace($location.url(), '') +
                '/shoppinglist?join=' + response.data.token;
		};
		
		var errorCallback = function (response) {
			notificationService.showError('alert.error_share_product_list', response);
		};
    }
})();
