(function () {
    'use strict';

    angular
        .module('überliste')
        .service('DialogService', DialogService);

    DialogService.$inject = ['$rootScope', 'ngDialog'];

    function DialogService($rootScope, ngDialog) {
        return {
            addProductList: addProductList,
            newProductListItem: newProductListItem,
            editProductListItem: editProductListItem,
            openDeleteListDialog: openDeleteListDialog,
            openShareListDialog: openShareListDialog,
            openLoginDialog: openLoginDialog,
            openProductsDialog: openProductsDialog,
            openSettingsDialog: openSettingsDialog,
            openDeleteHistoryItemDialog: openDeleteHistoryItemDialog,
            openAlertDialog: openAlertDialog,
            // openNewProductDialog: openNewProductDialog,
            getOpenDialogs: getOpenDialogs
        };

        function addProductList() {
            var dialog = ngDialog.open({
                template: 'template/dialog/productList.html',
                controller: 'NewProductListController',
                scope: $rootScope.$new()
            });
            return dialog;
        }

        function newProductListItem(productName, userDefined, categoriesCatalog, measurementsCatalog) {
            var resolves = {
                categoriesCatalog: function () { return categoriesCatalog; },
                measurement: function(){ return measurementsCatalog.getById(); },
                dialogTitle: function(){ return 'new_list_item_panel.title'; },
                draftListItem: function () {
                    return {
                        productName: productName,
                        userDefined: userDefined,
                        category: categoriesCatalog.defaultCategory,
                        upperCategory: categoriesCatalog.defaultCategory
                    };
                }
            };
            var dialog = ngDialog.open({
                template: 'template/dialog/productListItem.html',
                controller: 'EditProductListItemController',
                controllerAs: '$ctrl',
                resolve: resolves,
                $scope: $rootScope.$new()
            });
            return dialog;
        }

        function editProductListItem(item, categoriesCatalog, measurementsCatalog) {
            var resolves = {
                categoriesCatalog: function () { return categoriesCatalog; },
                dialogTitle: function(){ return 'edit_list_item_panel.title'; },
                measurement: function(){ return measurementsCatalog.getById(item.measurementPmId); },
                draftListItem: function () {
                    var copyItem = angular.copy(item);
                    copyItem.category = categoriesCatalog.getById(item.categoryPmId);
                    copyItem.upperCategory = categoriesCatalog.getUpperCategoryById(item.categoryPmId);
                    return copyItem;
                }
            };

            var dialog = ngDialog.open({
                template: 'template/dialog/productListItem.html',
                controller: 'EditProductListItemController',
                controllerAs: '$ctrl',
                resolve: resolves,
                scope: $rootScope.$new()
            });
            return dialog;
        }

        function openDeleteListDialog(list) {
            var scope = $rootScope.$new();
            scope.listToDelete = list;

            var dialog = ngDialog.open({
                template: 'template/dialog/deleteProductList.html',
                controller: 'DeleteProductListController',
                scope: scope
            });
            return dialog;
        }

        function openShareListDialog(list) {
            var scope = $rootScope.$new();
            scope.activeList = list;

            var dialog = ngDialog.open({
                template: 'template/dialog/shareList.html',
                controller: 'ShareListController',
                scope: scope
            });
            return dialog;
        }

        function openLoginDialog(scope) {
            var dialog = ngDialog.open({
                template: 'template/dialog/login.html',
                controller: 'LoginController',
                scope: scope
            });
            return dialog;
        }

        function openProductsDialog(categoriesCatalog) {
            var scope = $rootScope.$new();
            scope.categoriesCatalog = categoriesCatalog;

            var dialog = ngDialog.open({
                template: 'template/dialog/userProducts.html',
                className: 'ngdialog-theme-default user-products-dialog',
                controller: 'UserProductsController',
                scope: scope
            });
            return dialog;
        }

        function openSettingsDialog() {
            var dialog = ngDialog.open({
                template: 'template/dialog/userSettings.html',
                controller: 'UserSettingsController',
                scope: $rootScope.$new()
            });
            return dialog;
        }

        function openDeleteHistoryItemDialog(itemToDelete) {
            var scope = $rootScope.$new();
            scope.purchase = itemToDelete;
            var dialog = ngDialog.open({
                template: 'template/dialog/deleteHistoryItem.html',
                controller: 'DeleteHistoryItemController',
                scope: scope
            });
            return dialog;
        }

        function openAlertDialog(message, type){
            var scope = $rootScope.$new();
            scope.alertType = type ? type : 'alert-warning';
            scope.alertMessage = message;

            var dialog = ngDialog.open({
                template: 'template/dialog/alert.html',
                scope: scope
            });

            return dialog;
        }

        function getOpenDialogs(){
            return ngDialog.getOpenDialogs();
        }

    }
})();
