(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('DeleteHistoryItemController', DeleteHistoryItemController);

    DeleteHistoryItemController.$inject = ['$scope', '$log', 'PurchaseHistoryEntryService'];

    function DeleteHistoryItemController($scope, $log, purchaseHistoryEntryService) {

        $scope.confirmDelete = confirmDelete;
        $scope.cancel = cancel;

        function confirmDelete() {

            purchaseHistoryEntryService.deleteByPmId($scope.purchase, function () {
                    // broadcastingService.sendBroadcastMessage(broadcastingService.broadcastMessages.purchaseItemChanged);
                    $scope.closeThisDialog();
                },
                function (response) {
                    $log.error('Error: history item ' + $scope.purchase + ' couldn\'t be deleted', response);
                });
        }

        function cancel() {
            $scope.closeThisDialog();
        }

    }
})();
