(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('NewProductListController', NewProductListController);

    NewProductListController.$inject = ['$log', '$scope', 'ProductListService'];

    function NewProductListController($log, $scope, productListService) {
        $scope.cancel = $scope.closeThisDialog;
        $scope.showNotification = false;
        $scope.notificationClass = 'alert-danger';
        $scope.notificationMsg = 'add_list_panel.create_list_error';

        $scope.apply = function(listName) {
            productListService
                .create(listName)
                .then($scope.cancel)
                .catch(function (response) {
                   $scope.showNotification = true;
                   $log.error(response);
                });
        };
    }
})();
