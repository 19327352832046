(function () {
    'use strict';

    angular
        .module('überliste')
        .service('ProductListService', ProductListService);

    ProductListService.$inject = ['$http', '$q', 'Session'];

    function ProductListService($http, $q, session) {
        return {
            create: create,
            get: get,
            update: update,
            delete: _delete,
            getActiveList: getActiveList,
            getProductListItems: getProductListItems,
            sync: sync,
            share: share,
            join: join,
            getIndexOfItemWithProductPmId: getIndexOfItemWithProductPmId
        };

        /**
         * Add a new [empty] product list with given name
         *
         * @param name
         */
        function create(name) {
            var url = '/api/v1/lists';

            var productList = {
                id: null,
                name: name,
                items: null,
                active: true,
                users: '',
                updated: Date.now()
            };

            return $http.post(url, productList);
        }

        /**
         * Get all user's product lists
         *
         * @param success
         * @param error
         */
        function get() {
            var url = '/api/v1/lists';

            return $http
                .get(url)
                .then(function (result) {
                    return result.data;
                });

        }

        /**
         * Update product list
         *
         * @param productList
         */
        function update(productList) {
            var url = '/api/v1/lists/' + productList.id;

            productList.updated = Date.now();
            return $http.put(url, productList);
        }

        /**
         * Delete product list
         *
         * @param productList
         * @param success
         * @param error
         */
        function _delete(productList, success, error) {
            var url = '/api/v1/lists/' + productList.id;

            productList.updated = Date.now();
            $http.delete(url).then(success, error);
        }

        /**
         * Return the active product list
         */
        function getActiveList() {
            var deferred = $q.defer();
            get()
                .then(function (lists) {
                    if (!lists || lists.length === 0) {
                        deferred.reject();
                    }

                    var activeList = lists.filter(function (list) {
                        return list.active;
                    }).pop();

                    if(activeList) {
                        deferred.resolve(activeList);
                    } else {
                        deferred.reject();
                    }
                })
                .catch(deferred.reject);

            return deferred.promise;
        }

        /**
         * Load product list items
         *
         */
        function getProductListItems(productList) {
            var url = '/api/v1/lists/' + productList.id + '/items';
            return $http
                .get(url)
                .then(function (result) {
                    return result.data;
                });
        }

        /**
         * Product list synchronization
         *
         * @param productList   List to sync
         * @param shouldNotify  Server should notify all bound mobile devices about changes.
         */
        function sync(productList, shouldNotify) {
            productList.updated = Date.now();
            var request = {
                method: 'POST',
                url: '/api/v1/lists/sync',
                headers: {
                    'token': session.token
                },
                data: {
                    productListDto: productList,
                    shouldNotify: shouldNotify,
                    returnWebDto: true
                }
            };

            return $http(request).then(function(response) {
                if(response.data) {
                    return response.data.webItems;
                } else {
                    return [];
                }
            });
        }

        /**
         * Load active product list items
         *
         * @param success
         * @param error
         */
        function share(activeListId) {
            var url = '/api/v1/lists/share?productListId=' + activeListId;
            return $http.post(url);
        }

        /**
         * Load active product list items
         *
         * @param success
         * @param error
         */
        function join(token) {
            var url = '/api/v1/lists/join?token=' + token;
            return $http.post(url);
        }

        /**
         * Finds the index of the item in the items list that has a pmId equal
         * to productPmId.
         *
         * @param items
         * @param productPmId
         * @returns {*}
         */
        function getIndexOfItemWithProductPmId(items, productPmId){
            return items.findIndex(function(e) {
                if(e.product){
                    return e.product === productPmId || (e.product.pmId && e.product.pmId === productPmId);
                }
                else if(e.productPmId){
                    return e.productPmId === productPmId;
                }
                return false;
            });
        }
    }
})();
