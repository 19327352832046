(function() {
    'use strict';

    angular
        .module('überliste')
        .config(translateConfig);

    function translateConfig($translateProvider) {
        $translateProvider
            .useStaticFilesLoader({
                prefix: 'i18n/i18n-',
                suffix: '.json'
            })
            .uniformLanguageTag('bcp47')
            .registerAvailableLanguageKeys(['en', 'de', 'ru'], {
                'en-US': 'en',
                'en-DE': 'en',
                'en-UK': 'en',
                'en-GB': 'en',
                'en-au': 'en',
                'en-ca': 'en',

                'de-DE': 'de',
                'de-CH': 'de',
                'de-at': 'de',
                'de-lu': 'de',
                'de-li': 'de',
                
                'ru_RU': 'ru'
            })
            .determinePreferredLanguage()
            .fallbackLanguage('en')
            .useSanitizeValueStrategy('escapeParameters')
            .useCookieStorage();
    }

})();
