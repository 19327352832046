(function() {
    'use strict';

    angular
        .module('überliste')
        .directive('mainFooter', footer);

    function footer() {
        return {
            templateUrl: 'template/directive/footer.html'
        };
    }
})();
