(function(){
    'use strict';

    angular
        .module('überliste')
        .component('historySortPanel', {
            templateUrl: 'template/component/historySortPanel.html',
            controller: SortPanelController,
            bindings: {
                categoriesCatalog: '<',
                sort: '&'
            }
        });

    SortPanelController.$inject = ['$scope'];

    function SortPanelController($scope) {
        var ctrl = this;
        ctrl.sortOptions = [];

        ctrl.$onInit = function() {
            initSortOptions();
        };

        ctrl.sortList = function(sortOption) {
            ctrl.sort({sortFn: sortOption.sort});
        };

        function initSortOptions() {
            var catalog = ctrl.categoriesCatalog;
            var idx = 0;

            catalog.upperCategories.forEach(function (category) {
                var pmId = category.pmId;

                ctrl.sortOptions.push({
                    sort: function(purchasesToSort) {
                        return sortByCategory(purchasesToSort, catalog, pmId);
                    },
                    imageName: catalog.getIconById(pmId),
                    color: catalog.getColorById(pmId),
                    order: idx++
                });
            });

            ctrl.sortOptions.push({
                sort: sortByCount,
                imageName: 'sortfreq_56m56.png',
                order: idx++
            });
            ctrl.sortOptions.push({
                sort: sortByName,
                imageName: 'sortalpha_56m56.png',
                order: idx++
            });

            $scope.$emit('activeSorter', {sort: sortByCount});
        }
    }

    function sortByName(arrayToSort) {
        arrayToSort.sort(function (a, b) {
            return a.product.name.localeCompare(b.product.name);
        });
        return arrayToSort;
    }

    function sortByOrder(arrayToSort){
        arrayToSort.sort(function (a, b) {
            if (parseInt(a.order) < parseInt(b.order)) { return -1; }
            if (parseInt(a.order) > parseInt(b.order)) { return 1; }
            return 0;
        });
        return arrayToSort;
    }

    function sortByCount(arrayToSort) {
        arrayToSort.sort(function (a, b) {
            // compare by purchase count
            var countDiff = b.count - a.count;
            if (countDiff !== 0) {
                return countDiff;
            }
            // if count equal compare by category
            else {
                if (a.categoryColor > b.categoryColor) {
                    return 1;
                }
                else if (a.categoryColor < b.categoryColor) {
                    return -1;
                }
                // if category equal compare by name
                else {
                    if (a.product.name > b.product.name) {
                        return 1;
                    }
                    else if (a.product.name < b.product.name) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                }
            }
        });
        return arrayToSort;
    }

    function sortByCategory(purchasesToSort, categoriesCatalog, sortCategoryPmId) {
        var restPurchases = [];
        var categoryPurchases = [];

        purchasesToSort.forEach(function (purchase) {
            var category = categoriesCatalog.getById(purchase.product.category);

            var upperCategory = categoriesCatalog.getUpperCategory(category);
            if (upperCategory && upperCategory.pmId === sortCategoryPmId) {
                categoryPurchases.push(purchase);
            }
            else {
                restPurchases.push(purchase);
            }
        });

        sortByName(categoryPurchases);
        sortByOrder(restPurchases);
        purchasesToSort = categoryPurchases.concat(restPurchases);
        return purchasesToSort;
    }
})();
