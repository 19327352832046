(function () {
    'use strict';

    angular
        .module('überliste')
        .component('productListItems', {
            templateUrl: 'template/component/productListItems.html',
            controller: ProductListItemsController,
            bindings: {
                activeList: '<',
                categoriesCatalog: '<',
                measurementsCatalog: '<',
                removeItem: '&',
                errorHandler: '&'
            }
        });

    ProductListItemsController.$inject = [
        '$q',
        '$rootScope',
        '$timeout',
        'DialogService',
        'ProductService',
        'ProductListItemService',
        'UserSettingsService'
    ];

    function ProductListItemsController($q,
                                        $rootScope,
                                        $timeout,
                                        DialogService,
                                        ProductService,
                                        ProductListItemService,
                                        UserSettingsService) {
        var ctrl = this;
        ctrl.showBought = false;

        var unbind = $rootScope.$on('userSettingsChanged', function (ev, settings) {
            ctrl.showBought = settings.showBoughtItemsInTheList;
        });

        ctrl.$onDestroy = function () {
            unbind();
        };

        ctrl.$onInit = function () {
            UserSettingsService.getSettings().then(function (settings) {
                ctrl.showBought = settings.showBoughtItemsInTheList;
            });
        };

        ctrl.editItem = function (item) {
            var dialog = DialogService.editProductListItem(item,
                ctrl.categoriesCatalog, ctrl.measurementsCatalog);

            dialog
                .closePromise
                .then(updateProduct)
                .then(updateProductListItem)
                .then(function (draftListItem) {
                    if (angular.isDefined(draftListItem)) {
                        angular.copy(draftListItem, item);
                    }
                })
                .catch(function (response) {
                    ctrl.errorHandler({
                        response: response,
                        message: 'edit_list_item_panel.error'
                    });
                });
        };

        function updateProduct(dialogValue) {
            if (angular.isUndefined(dialogValue.value) || dialogValue.value === '$closeButton') {
                return $q.when();
            }

            var draftListItem = dialogValue.value;
            if (draftListItem.userDefined) {

                var product = {
                    pmId: draftListItem.productPmId,
                    name: draftListItem.productName,
                    category: draftListItem.category.pmId,
                    userDefined: true
                };

                return ProductService
                    .update(product)
                    .then(function () {
                        // TODO: if the category was changed, the position of the item should be updated too
                        return draftListItem;
                    });
            } else {
                return $q.when(draftListItem);
            }
        }

        function updateProductListItem(draftListItem) {
            if (angular.isUndefined(draftListItem)) {
                return $q.when();
            }
            return ProductListItemService
                .update(draftListItem)
                .then(function () {
                    return draftListItem;
                });
        }

        ctrl.deleteItem = function (item) {
            item.internalState = 'WILL_BE_DELETED';
            $timeout(function () {
                item.state = 'DELETED';
                ProductListItemService
                    .update(item)
                    .then(function () {
                        ctrl.removeItem({item: item});
                    });
            }, 200);
        };

        ctrl.buyItem = function (item) {
            item.internalState = 'WILL_BE_BOUGHT';
            $timeout(function () {
                if (item.state.toUpperCase() === 'NEW') {
                    item.state = 'BOUGHT';
                } else if (item.state.toUpperCase() === 'BOUGHT') {
                    item.state = 'DELETED';
                }

                ProductListItemService
                    .update(item)
                    .then(function () {
                        if (item.state === 'DELETED' || item.state === 'BOUGHT') {
                            ctrl.removeItem({item: item});
                        }
                    });
            }, 200);
        };

    }
})();

