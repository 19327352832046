(function () {
    'use strict';

    angular
        .module('überliste')
        .service('PurchaseHistoryEntryService', PurchaseHistoryEntryService);

    PurchaseHistoryEntryService.$inject = ['$http'];

    function PurchaseHistoryEntryService($http) {
        var historySize = '30';

        return {
            get: get,
            deleteByPmId: delete_
        };

        function get() {
            var url = '/api/v1/purchases?historySize=' + historySize;
            return $http.get(url);
        }

        function delete_(purchase, success, error) {
            var url = '/api/v1/purchases/' + purchase.product.pmId;
            $http.delete(url, purchase).then(success, error);
        }

    }
})();
