(function() {
    'use strict';

    angular
        .module('überliste')
        .component('userProductEditRow', {
            templateUrl: 'template/component/userProductEditRow.html',
            controller: UserProductEditRowController,
            bindings: {
                product: '<',
                categoriesCatalog: '<',
                setProductCategory: '&',
                setProductName: '&',
                deleteProduct: '&'
            }
        });

    function UserProductEditRowController() {
        var ctrl = this;

        ctrl.setName = function(name) {
            ctrl.setProductName({
                product: ctrl.product,
                name: name
            });
        };

        ctrl.removeProduct = function() {
            ctrl.deleteProduct({product: ctrl.product});
        };

        ctrl.setCategoryOfProduct = function(category) {
            ctrl.setProductCategory({
                product: ctrl.product,
                category: category
            });
        };
    }
})();