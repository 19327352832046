(function () {
    'use strict';

	var dependencies = [
        'ngCookies',
        'pascalprecht.translate',
        'ngRoute',
        'ngWebSocket',
        'ngDialog',
        'ngAnimate',
        'ngTouch',
        'ui.bootstrap',
        'duScroll'
    ];

    angular
    	.module('überliste', dependencies)
		.run(function ($rootScope, $location, $window, $anchorScroll) {
			// Scroll to top in the new page
			var unregisterLocationChangeSuccess = $rootScope.$on('$locationChangeSuccess', function () {
				$anchorScroll();
			});

			$rootScope.$on('$destroy', function(){
				unregisterLocationChangeSuccess();
			});
		});
})();
