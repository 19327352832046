(function() {
	'use strict';

	angular
		.module('überliste')
		.service('Session', Session);

    Session.$inject = ['$translate', 'BroadcastingService'];

    function Session($translate, broadcastingService) {
        this.token = Math.random().toString(36).substr(2);

        this.create = function (email) { this.email = email; };
        this.destroy = function () { this.email = null; };

        this.update = function (settings) {
            this.settings = settings;
            $translate.use(settings.language);
            broadcastingService.sendBroadcastMessage(broadcastingService.broadcastMessages.updateSession);
        };
    }
})();
