(function () {
    'use strict';

    angular
        .module('überliste')
        .config(routeConfig);

    function routeConfig($locationProvider, $routeProvider) {
        $locationProvider.html5Mode(true);
        $routeProvider
            .when('/', {
                templateUrl: 'template/partial/homepage.html',
                controller: 'HomepageController',
                resolveRedirectTo: init
            })
            .when('/shoppinglist', {
                templateUrl: 'template/partial/shoppingList.html',
                controller: 'ShoppingListController',
                resolve: {
                    Measurements: ['MeasurementService', function(MeasurementService) {
                        return MeasurementService.get();
                    }],
                    Categories: ['ProductCategoryService', function(ProductCategoryService) {
                        return ProductCategoryService.get();
                    }],
                    Auth: ['LoginService', function(LoginService) {
                        return LoginService.isAuthenticated();
                    }]
                }
            })
            .when('/loginexpired', {
                templateUrl: 'template/partial/homepage.html',
                controller: 'HomepageController'
            })
            .when('/imprint', {
                templateUrl: 'template/partial/imprint.html',
                controller: 'HomepageController'
            })
            .when('/terms', {
                templateUrl: 'template/partial/terms.html',
                controller: 'HomepageController'
            })
            .when('/contact', {
                templateUrl: 'template/partial/contact.html',
                controller: 'HomepageController'
            })
            .when('/help', {
                templateUrl: 'template/partial/userManual.html',
                controller: 'HomepageController'
            })
            .when('/accountDeleted', {
                templateUrl: 'template/partial/homepage.html',
                controller: 'HomepageController'
            })
            .otherwise({
                redirectTo: '/'
            });
    }

    init.$inject = ['LoginService'];
    
    function init(LoginService) {
        return LoginService
            .isAuthenticated()
            .then(function () { return '/shoppinglist'; })
            .catch(function() { return undefined; });
    }

})();
