(function () {
    'use strict';

    angular
        .module('überliste')
        .service('UserService', UserService);

    UserService.$inject = ['$http', '$translate'];

    function UserService($http, $translate) {
        var url = '/api/v1/users';

        return {
            create: create,
            update: update,
            get: get,
            getLoginStatus: getLoginStatus,
            getUserLanguage: getLanguage,
            changePassword: changePassword,
            resetPassword: resetPassword,
            deleteUser: deleteUser,
        };

        function create(email, success, error) {
            var user = {
                email: email,
                language: getLanguage(),
                createDefaults: true
            };

            $http.post(url + '/signup', user).then(success, error);
        }

        function update(user) {
            return $http.put(url, user);
        }

        function get() {
            return $http.get(url);
        }

        function getLoginStatus(success, error) {
            $http.get(url + '/loginstatus').then(success, error);
        }

        function getLanguage() {
            return $translate.use() ? $translate.use() : $translate.proposedLanguage();
        }

        function changePassword(oldPassword, newPassword, success, error) {
            $http.put(url + '/changepassword?oldPassword=' + encodeURIComponent(oldPassword) + '&newPassword=' + encodeURIComponent(newPassword)).then(success, error);
        }

        function resetPassword(email, success, error) {
            $http.post(url + '/resetpassword?email=' + email).then(success, error);
        }
        function deleteUser(password, success, error){
            $http.delete(url + '/deleteuser?password=' +encodeURIComponent(password)).then(success,error);
        }

    }
})();
