(function () {
	'use strict';
	angular
		.module('überliste')
		.directive('googleSignIn',['UserService', 'LoginService',
			function ( userService, loginService) {
				return {
					restrict: 'E',
					link: postLink
				};
				function postLink(scope,elem) {
					var auth2;
					loginService.gapiPromise().then( function (gapi){
						gapi.load('auth2', function() {
							auth2 = gapi.auth2.init({
								CLIENT_ID: '517104027080-49t2g3v5jkhd48gih6gsfj6veebr867m.apps.googleusercontent.com',
								cookiepolicy: 'single_host_origin',
								scope: 'profile email'
							});
							var element = elem[0];
							var ID_TOKEN_PARAM = 'id_token';
							auth2.attachClickHandler(element, {}, function(googleUser) {
								var data = {
									token: googleUser.getAuthResponse()[ID_TOKEN_PARAM],
									language: userService.getUserLanguage()
								};
								scope.googleSignIn(data);
							});
						});
					});
				}
			}]);
})();
