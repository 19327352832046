(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageDescription', function () {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageDescription.html'
            };
        });
})();
