(function () {
    'use strict';

    angular
        .module('überliste')
        .service('ProductCategoryService', ProductCategoryService);

    ProductCategoryService.$inject = ['$http', 'UserService'];

    function ProductCategoryService($http, userService) {
        return {
            get: get
        };

        function get() {
            var url = '/api/v1/categories?language=' + userService.getUserLanguage();
            return $http
                .get(url)
                .then(function (result) { return result.data; });
        }
    }
})();
