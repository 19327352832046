(function() {
    'use strict';

    angular
        .module('überliste')
        .component('productListItem', {
            templateUrl: 'template/component/productListItem.html',
            controller: ProductListItemController,
            bindings: {
                item: '<',
                onEdit: '&',
                onDelete: '&',
                measurementsCatalog: '<'
            }
        });

    function ProductListItemController() {
        var ctrl = this;

        ctrl.editItem = function() {
            if(ctrl.item.state.toUpperCase() === 'NEW') {
                ctrl.onEdit({item: ctrl.item});
            }
        };

        ctrl.deleteItem = function () {
            ctrl.onDelete({item: ctrl.item});
        };
        
        ctrl.showItemAmount = function() {
            var amount = ctrl.measurementsCatalog.getById(ctrl.item.measurementPmId);
            if(! amount || amount.plural === '-' || amount.shortcut === '&ndash;') { return ''; }
            if(ctrl.item.amount === 1) {
                return amount.shortcut;
            } else {
                return amount.plural;
            }
        };
    }
})();
