(function() {
    'use strict';

    angular
        .module('überliste')
        .component('productListSelect', {
            templateUrl: 'template/component/productListSelect.html',
            controller: ProductListSelectController,
            bindings: {
                activeList: '<',
                setActiveList: '&',
                updateActiveListName: '&',
                errorHandler: '&'
            }
        });

    ProductListSelectController.$inject = ['$rootScope', 'ProductListService', 'DialogService'];

    function ProductListSelectController($rootScope, ProductListService, DialogService) {
            var ctrl = this;
            ctrl.productLists = [];
            ctrl.activeList = { name: '' }; // model value for the product list name

            var events = [
                { name: 'joinList', unbind: angular.noop },
                { name: 'ws:product:update', unbind: angular.noop },
                { name: 'ws:productlist:create', unbind: angular.noop },
                { name: 'ws:productlist:update', unbind: angular.noop },
                { name: 'ws:productlist:delete', unbind: angular.noop },
                { name: 'ws:productlistitem:create', unbind: angular.noop }
            ];

            ctrl.$onDestroy = function() {
                angular.forEach(events, function(event) {
                    event.unbind();
                });
            };

            ctrl.$onInit = function() {
                loadProductLists();

                angular.forEach(events, function(event) {
                    var fn = angular.noop;

                    if(event.name === 'ws:productlist:update') {
                        fn = function(ev, data) {
                            for (var i in ctrl.productLists) {
                                var list = ctrl.productLists[i];
                                if (list.id === data.id) {
                                    list.name = data.name;
                                    list.users = data.users;
                                    return;
                                }
                            }
                        };
                    } else if(event.name === 'ws:productlist:delete') {
                        fn = function(event, id) {
                            var listIndex = ctrl.productLists.findIndex(function (list) {
                                return list.id === id;
                            });

                            if(listIndex) {
                                ctrl.productLists.splice(listIndex, 1);
                            }

                            if (ctrl.activeList.id === id) {
                                ctrl.setActiveList({list: ctrl.productLists[0]});
                                ctrl.activeList = { name: ctrl.productLists[0].name };
                            }
                        };
                    } else {
                        fn = loadProductLists;
                    }

                    event.unbind = $rootScope.$on(event.name, fn);
                });
            };

            function loadProductLists() {
                ProductListService
                    .get()
                    .then(function(productLists) {
                        ctrl.productLists = [];
                        productLists.forEach(function (list) {
                            enrichList(list);
                            ctrl.productLists.push(list);

                            if(list.active === true) {
                                ctrl.setActiveList({list: list});
                                ctrl.activeList = { name: list.name };
                            }
                        });
                    })
                    .catch(function(response) {
                        ctrl.errorHander({
                            response: response,
                            message: 'alert.error_load_product_lists'
                        });
                    });
            }

            function enrichList(list) {
                list.itemsCount = list.items.length;
                list.items = [];
            }

            ctrl.shareProductList = function(list) {
                DialogService.openShareListDialog(list);
            };

            ctrl.deleteProductList = function(list) {
                if(ctrl.productLists.length > 1) {
                    DialogService
                        .openDeleteListDialog(list)
                        .closePromise
                        .then(loadProductLists);
                }
            };

            ctrl.addNewProductList = function() {
                DialogService
                    .addProductList()
                    .closePromise
                    .then(loadProductLists);
            };

            ctrl.updateListName = function() {
                ctrl.updateActiveListName({newName: ctrl.activeList.name});
            };

            ctrl.activateList = function(list) {
                ctrl.setActiveList({list: list});
            };
    }
})();