(function() {
    'use strict';

    angular
        .module('überliste')
        .component('productListSelectItem', {
            templateUrl: 'template/component/productListSelectItem.html',
            controller: ProductListSelectItemController,
            bindings: {
                list: '<',
                listsSize: '<',
                shareList: '&',
                deleteList: '&',
                selectActiveList: '&'
            }
        });
    
    function ProductListSelectItemController() {
        var ctrl = this;

        ctrl.shareProductList = function() {
            ctrl.shareList({list: ctrl.list});
        };

        ctrl.deleteProductList = function() {
            ctrl.deleteList({list: ctrl.list});
        };

        ctrl.activateProductList = function() {
            ctrl.selectActiveList({list: ctrl.list});
        };
    }
})();