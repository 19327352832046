(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('LoginController', LoginController);

    LoginController.$inject = [
    	'$rootScope',
    	'$scope',
    	'$translate',
    	'$log',
    	'LoginService',
    	'UserService',
        'NavigationService',
        'NotificationService',
        'Session'];

    function LoginController(
    	$rootScope,
    	$scope,
    	$translate,
    	$log,
    	loginService,
    	userService,
        navigationService,
        notificationService) {
        notificationService.setControllerScope($scope);


        if ($scope.expired) {
            notificationService.showError('login_panel.expired');
        }

        notificationService.addWatch('credentials.email');
        notificationService.addWatch('credentials.password');

        var success = function () {
            userService.get().then(function (res) {
                $translate.use(res.data.language);
            });

            $scope.closeThisDialog();
            navigationService.navigateToShoppingList();
        };

        // Scope variables
        $scope.mode = $scope.mode || 'LOGIN';
        $scope.credentials = {
            email: '',
            password: ''
        };

        $scope.changeMode = function () {
            if ($scope.mode === 'LOGIN') {
                $scope.mode = 'FORGOT_PASSWORD';
                notificationService.resetNotification();
            } else {
                $scope.mode = 'LOGIN';
            }
        };

        /*
         * Submit login dialog implements two actions:
         *
         * 1. Username-password login
         * 2. Reset password
         */
        $scope.submit = function (credentials) {
            if ($scope.mode === 'LOGIN') {
                loginService.login(credentials)
                    .then(function () {
                        success();
                    }, function () {
                        notificationService.showError('login_panel.login_failed');
                    });
            } else if ($scope.mode === 'REGISTRATION') {
                userService.create($scope.credentials.email, function(){
                    $scope.$root.onRegistrationCompleted = true;
                    $scope.closeThisDialog();
                }, function(response){
                    $scope.$root.onRegistrationCompleted = false;
                    if (response.status === 409) {
                        notificationService.showError('create_free_account_screen.create_account_already_registered_error');
                    } else {
                        notificationService.showError('create_free_account_screen.create_account_error');
                    }
                });
            } else if($scope.mode === 'FORGOT_PASSWORD') {
                userService.resetPassword($scope.credentials.email, function () {
                    $scope.$root.onPasswordReseted = true;
                    $scope.closeThisDialog();
                }, function () {
                    notificationService.showError('forgot_password.forgot_password_error');
                });
            } else {
                $log.warn('Unknown mode ' + $scope.mode);
            }
        };

        /*
         * Facebook login
         */
        $scope.fbLogin = function () {
            loginService
                .socialLogin('facebook')
                .then(loginService.isAuthenticated)
                .then(success)
                .catch(function () {
                    notificationService.showError('login_panel.login_failed');
                });
        };

        $scope.cancel = $scope.closeThisDialog;
    }
})();
