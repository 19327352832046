(function() {
	'use strict';

	angular
		.module('überliste')
		.component('categoryDropdown', {
            templateUrl: 'template/component/categoryDropdown.html',
			controller: CategoryDropDownController,
			bindings: {
            	categories: '<',
                selectedCategory: '<',
				disabled: '<',
				onSelect: '&'
			}
		});

		function CategoryDropDownController() {
			var ctrl = this;

			ctrl.select = function (item) {
				ctrl.onSelect({category: item});
			};
		}
})();
