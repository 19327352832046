(function () {
    'use strict';

	var dependencies = [
        'überliste',
        'ezfb'
    ];

    angular
    	.module('wrapper', dependencies)
		.run(function ($rootScope, $location, $window) {
			// Push a tracking event to Google Analytics
			var unregisterViewContentLoaded = $rootScope.$on('$viewContentLoaded', function () {
				$window.ga('send', 'pageview', {page: $location.url()});
			});

			$rootScope.$on('$destroy', function(){
				unregisterViewContentLoaded();
			});
		})
        .controller('WrapperController', WrapperController);

    WrapperController.$inject = ['$scope'];

    function WrapperController($scope) {
        $scope.isNotIE = !/msie|trident|edge/g.test(navigator.userAgent.toLowerCase());
    }

})();
