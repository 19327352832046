(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('UserProductsController', UserProductsController);

    UserProductsController.$inject = ['$scope', '$log', 'ProductService', 'BroadcastingService'];

    function UserProductsController($scope, $log, productService, broadcastingService) {
        // List of user-defined products
        $scope.products = [];

        $scope.hoverIn = function (product) {
            product.hoverEdit = true;
        };

        $scope.hoverOut = function (product) {
            product.hoverEdit = false;
        };

        $scope.setName = setName;
        $scope.setCategory = setCategory;
        $scope.deleteProduct = deleteProduct;

        // Loading data
        function loadProducts() {
            $scope.products = [];
            productService.getOwn(function(result) {
                $scope.products = result.data;
                for (var i in $scope.products) {
                    var product = $scope.products[i];
                    var category = $scope.categoriesCatalog.getById(product.category);
                    if (category !== null) {
                        product.color = category.defaultColor;
                    }
                }
            }, function () {
                $log.log('Error loading user-defined products.');
            });
        }
        loadProducts();

        // Actions
        function setName(product, name) {
            product.name = name;
            productService.update(product, function() {
                // success
                broadcastingService.sendBroadcastMessage(broadcastingService.broadcastMessages.listItemChanged);
            }, function() {
                $log.log('Error updating product name.');
            });
        }

        function setCategory(product, category) {
            product.category = category.pmId;
            productService.update(product, function() {
                // success
                product.color = category.defaultColor;
                broadcastingService.sendBroadcastMessage(broadcastingService.broadcastMessages.listItemChanged);
            }, function() {
                $log.log('Error updating product category.');
            });
        }

        function deleteProduct(product) {
            productService.delete(product, function() {
                // success
                var idx = $scope.products.indexOf(product);
                $scope.products.splice(idx, 1);
                broadcastingService.sendBroadcastMessage(broadcastingService.broadcastMessages.listItemChanged);
            }, function() {
                $log.log('Error deleting product.');
            });
        }

    }
})();
