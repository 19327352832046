(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('HomepageController', HomepageController);

    HomepageController.$inject = ['$scope', '$http', '$httpParamSerializerJQLike', '$location', '$routeParams', 'LoginService', 'UserService', 'NavigationService',
        'NotificationService', 'DialogService','Session','$timeout', '$anchorScroll', '$translate'];

    function HomepageController($scope, $http, $httpParamSerializerJQLike, $location, $routeParams, loginService, userService, navigationService,
                                notificationService, dialogService, session, $timeout, $anchorScroll, $translate) {
        notificationService.setControllerScope($scope);

        if ($location.path() === '/loginexpired') {
            notificationService.showWarn('session.expired');
        } else if ($routeParams.passwordchanged === 'true') {
            notificationService.showSuccess('forgot_password.password_changed');
        } else if ($routeParams.passwordchanged === 'false') {
            notificationService.showError('forgot_password.password_not_changed');
        } else if ($location.path() === '/accountDeleted') {
            notificationService.showSuccess('create_free_account_screen.delete_account_success');
        }

        $scope.credentials = {
            email: '',
            password: ''
        };
	    var config = {
		    headers : {
			    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
		    }
	    };
        $scope.scrollToPosition = function(hash){
          $anchorScroll(hash);
        };
        $scope.openForgotPasswordDialog = function () {
            $scope.mode = 'FORGOT_PASSWORD';
            var dialog =  dialogService.openLoginDialog($scope);
            dialog.closePromise.then(function () {
                $scope.dialogOpened = false;
                $scope.credentials.email = session.email;
                if($scope.$root.onPasswordReseted){
                    notificationService.setControllerScope($scope);
                    notificationService.showSuccess('forgot_password.forgot_password_success');
                }
            });
        };

        $scope.openRegistrationDialog = function () {
            $scope.mode = 'REGISTRATION';
            var dialog = dialogService.openLoginDialog($scope);
            dialog.closePromise.then(function () {
                $scope.dialogOpened = false;
                $scope.credentials.email = session.email;
                if($scope.$root.onRegistrationCompleted){
                    notificationService.setControllerScope($scope);
                    notificationService.showSuccess('create_free_account_screen.create_account_success');
                }
            });
        };
        $scope.onLoggedIn = function () {
            $scope.loginFailed = false;
            $scope.loginInProgress = false;
            navigationService.navigateToShoppingList();
        };
	
	    $scope.onLoginFailed = function () {
            $scope.loginFailed = true;
            $scope.loginInProgress = false;
        
            notificationService.showError('login_panel.login_failed');
        };
        
        $scope.submit = function (credentials) {
            $scope.loginInProgress = true;
            loginService.login(credentials)
                .then(function (email) {
                    session.create(email);
	                $scope.onLoggedIn();
                }, $scope.onLoginFailed);
        };

        $scope.fbLogin = function () {
            $scope.loginInProgress = true;
            loginService
                .socialLogin('facebook')
                .then(loginService.isAuthenticated)
                .then($scope.onLoggedIn)
                .catch($scope.onLoginFailed);
        };
        $scope.googleSignIn = function(data){
	        $http.post('/signin/google', $httpParamSerializerJQLike(data), config)
	        	.then(loginService.isAuthenticated)
	        	.then($scope.onLoggedIn)
	        	.catch($scope.onLoginFailed);
        };
        $scope.germanFlag = false;
        $scope.englishFlag = false;
        $scope.russianFlag = false;
        $scope.selectedLang = {name:'', img:''};
        $scope.languageOptions = [
            {id:'de', name: 'uss.language_de', img: 'img/de.png'},
            {id:'en',name: 'uss.language_en', img: 'img/gb.png'},
            {id:'ru',name: 'uss.language_ru', img: 'img/ru.png'}
        ];
    
        $scope.selectedLanguage = function(){
            if(!localStorage.getItem('lang')){
                if(navigator.language.includes('de')){
                    localStorage.setItem('lang','de');
                    $scope.selectedLang = $scope.languageOptions[0];
                    $scope.germanFlag = true;
                    $scope.englishFlag = false;
                    $scope.russianFlag = false;
                    $translate.use('de');
                }
                else if(navigator.language.includes('ru')){
                    localStorage.setItem('lang','ru');
                    $scope.selectedLang = $scope.languageOptions[2];
                    $scope.germanFlag = false;
                    $scope.englishFlag = false;
                    $scope.russianFlag = true;
                    $translate.use('ru');
                }
                else {
                    localStorage.setItem('lang','en');
                    $scope.selectedLang = $scope.languageOptions[1];
                    $scope.germanFlag = false;
                    $scope.englishFlag = true;
                    $scope.russianFlag = false;
                    $translate.use('en');
                }
            }else {
                if(localStorage.getItem('lang') === 'de') {
                    $scope.selectedLang = $scope.languageOptions[0];
                    $scope.germanFlag = true;
                    $scope.englishFlag = false;
                    $scope.russianFlag = false;
                    $translate.use('de');
                }
                if(localStorage.getItem('lang') === 'en'){
                    $scope.selectedLang = $scope.languageOptions[1];
                    $scope.germanFlag = false;
                    $scope.englishFlag = true;
                    $scope.russianFlag = false;
                    $translate.use('en');
                }
                if(localStorage.getItem('lang') === 'ru') {
                    $scope.selectedLang = $scope.languageOptions[2];
                    $scope.germanFlag = false;
                    $scope.englishFlag = false;
                    $scope.russianFlag = true;
                    $translate.use('ru');
                }
            }
        };
        $scope.selectedLanguage();
        $scope.enableListItems = false;
        $scope.enableList = function(){
            $scope.enableListItems = true;
        };
        $scope.languageSelection = function(language){
            $scope.enableListItems = false;
            localStorage.setItem('lang', language.id);
            $scope.selectedLang = language;
            switch (language.id) {
                case 'en':
                    localStorage.setItem('lang', 'en');
                    $scope.germanFlag = true;
                    $scope.englishFlag = false;
                    $scope.russianFlag = false;
                    break;
                case 'de':
                    localStorage.setItem('lang','de');
                    $scope.germanFlag = false;
                    $scope.englishFlag = true;
                    $scope.russianFlag = false;
                    break;
                case 'ru':
                    localStorage.setItem('lang','ru');
                    $scope.germanFlag = false;
                    $scope.englishFlag = false;
                    $scope.russianFlag = true;
                    break;
                    
            }
            $translate.use(language.id);
        };
    }
})();
