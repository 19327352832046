(function () {
    'use strict';

    angular
        .module('überliste')
        .service('MeasurementService', MeasurementService);

    MeasurementService.$inject = ['$http', 'UserService'];

    function MeasurementService($http, userService) {
        return {
            get: get
        };

        function get() {
            var url = '/api/v1/measurements?language=' + userService.getUserLanguage();
            return $http
                .get(url)
                .then(function (result) {
                    return result.data;
                });
        }

    }
})();
