(function() {
	'use strict';

	angular
		.module('überliste')
		.directive('notification', NotificationFn);

		function NotificationFn() {
			return {
				restrict: 'EA',
				templateUrl: 'template/directive/notification.html',
				scope: {
					notifclass: '=',
					notifmsg: '=',
					notifshow: '='
				}
			};
		}
})();
