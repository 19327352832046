(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageMain', function () {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageMain.html'
            };

            // FIXME: Uses parent component scope!
        });
})();
