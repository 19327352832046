(function() {
	'use strict';

	angular
		.module('überliste')
        .factory('httpInterceptor', HttpInterceptor)
        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('httpInterceptor');
        }]);

    HttpInterceptor.$inject = ['$q', '$rootScope', '$location', '$injector'];

    function HttpInterceptor($q, $rootScope, $location, $injector) {
        return {
            'request': function(config) {
                if (config.url.indexOf('api/v1') > -1) {
                    if (!config.headers) {
                        config.headers = {};
                    }

                    var Session = $injector.get('Session'); // Inject at runtime or else a circular dependency is created

                    config.headers.token =
                        config.headers.token ?
                            config.headers.token :
                            Session.token;
                }

                return config;
            },
            // Error provider that redirects to login page on unauthenticated requests
            'responseError': function (rejection) {
                var status = rejection.status;
                var config = rejection.config;
                var method = config.method;
                var url = config.url;

                if ($location.path() !== '/' && status === 401) {
                    $location.path('/loginexpired');
                } else {
                    $rootScope.error = method + ' on ' + url + ' failed with status ' + status;
                }

                return $q.reject(rejection);
            }
        };
    }
})();
