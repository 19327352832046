(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageDownload', ['$document', function ($document) {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageDownload.html',
                link: function(scope, element) {
                    var $el = element[0];
                    $document.on('scroll', function() {
                        // Use jQuery for performance optimization
                        var rect = $el.getBoundingClientRect();
                        var div = document.querySelector('.homepage-download-block');
                        if (div) {
                            if (rect.top <= 0 && rect.bottom > 0) {
                                div.style.position = 'fixed';
                                div.style.top = 0;
                                div.style.right = '15px';
                            } else {
                                div.style.position = 'relative';
                                div.style.right = 0;
                            }
                        }
                    });
                }
            };
        }]);
})();
