(function() {
    'use strict';

    angular
        .module('überliste')
        .component('productSearch', {
            templateUrl: 'template/component/productSearch.html',
            controller: ProductSearchController,
            bindings: {
                activeList: '<',
                categoriesCatalog: '<',
                measurementsCatalog: '<',
                insertSelectedItem: '&',
                errorHandler: '&'
            }
        });

    ProductSearchController.$inject = [
        '$scope',
        '$q',
        '$timeout',
        'DialogService',
        'ProductService',
        'ProductListService'];

    function ProductSearchController(
        $scope,
        $q,
        $timeout,
        DialogService,
        ProductService,
        ProductListService) {
            var ctrl = this;
            var searchResults = []; // last returned search results

            ctrl.searchWord = '';

            ctrl.addNewProductListItem = function() {
                var dialog = DialogService.newProductListItem(
                    ctrl.searchWord,
                    true,
                    ctrl.categoriesCatalog,
                    ctrl.measurementsCatalog);

                dialog
                    .closePromise
                    .then(createProduct)
                    .then(selectProduct)
                    .catch(function(response) {
                        ctrl.errorHander({
                            response: response,
                            message: 'new_list_item_panel.error'
                        });
                    });
            };

            ctrl.getProductById = function(id) {
                return $q(function(resolve, reject) {
                    ProductService.getById(id, resolve, reject);
                }).then(function(response) {
                    return response.data;
                }).catch(function(error) {
                    throw error;
                });
            };

            function createProduct(dialogValue) {
                if(dialogValue.value === '$closeButton'){ return $q.when(); }

                var draftListItem = dialogValue.value;

                var product = {
                    'name': draftListItem.productName,
                    'category': draftListItem.category.pmId
                };

                return $q.all([ProductService.create(product), $q.when(draftListItem)]);
            }

            function selectProduct(resolves) {
                if(resolves) {
                    var result = resolves[0].data;
                    var draftListItem = resolves[1];
                    draftListItem.pmId = result.pmId;
                    draftListItem.category = result.category;
                    ctrl.selectSearchResult(draftListItem);
                }

                return $q.when();
            }

            // use scope here for the typeahead expression
            $scope.searchProducts = function() {
                if(ctrl.searchWord.charAt(0) === '#') {
                    return localSearch(ctrl.searchWord);
                } else {
                    return remoteSearch(ctrl.searchWord);
                }
            };

            function localSearch(searchWord) {
                var searchTerm = searchWord.split(': ')[1];
                if (searchTerm && angular.isArray(searchResults)) {
                    searchResults = searchResults.filter(function (item) {
                        return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
                    });
                }

                return $q.resolve(searchResults);
            }

            function remoteSearch(searchWord) {
                return ProductService
                    .search(searchWord)
                    .then(function (data) {
                        searchResults = processData(data.hashtags, data.products);
                        return searchResults;
                });
            }



            function processData(hashTags, products) {
                var hashTagArray = hashTags !== null ? hashTags : [];
                var productArray = products !== null ? products : [];

                hashTagArray.forEach(function (ht) { ht.isHashtag = true; });

                var productPromises = productArray.map(function (product) {
                    var productCategoryPromise;

                    if (product.userDefined) {
                        productCategoryPromise = ctrl.getProductById(product.pmId)
                            .then(function(productWithCategory) {
                                return productWithCategory.category;
                            });
                    } else {
                        productCategoryPromise = $q.resolve(Math.floor(product.pmId / 1000000) * 100000);
                    }

                    return productCategoryPromise.then(function (productCategory) {
                        var idx = ProductListService.getIndexOfItemWithProductPmId(ctrl.activeList.items, product.pmId);
                        if(idx > -1) {
                            var item = ctrl.activeList.items[idx];
                            if (item.product === product.pmId) {
                                product.isDisabled = true;
                            }
                        }

                        product.isProduct = true;
                        product.defaultColor = ctrl.categoriesCatalog.getColorById(productCategory);
                    });
                });

                return $q.all(productPromises).then(function() {
                    return hashTagArray.concat(productArray);
                });
            }

            ctrl.selectSearchResult = function (item) {
                if (!item.isDisabled) {
                    if (item.isHashtag) {
                        searchResults = processData(item.childHashtags, item.products);
                        ctrl.searchWord = '#' + item.name + ': ';
                        $timeout(function () {
                            angular.element(document.querySelector('#searchBar')).triggerHandler('input');
                        });
                    } else {
                        ctrl.searchWord = '';
                        ctrl.insertSelectedItem({item: item});
                    }
                } else {
                    ctrl.searchWord = '';
                }
            };
    }
})();