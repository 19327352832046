(function() {
    'use strict';

    angular
        .module('wrapper')
        .config(facebookConfig);

    facebookConfig.$inject = ['ezfbProvider'];

    function facebookConfig(ezfbProvider) {
        ezfbProvider.setInitParams({
            appId: '285790838706044',
            xfbml: true,
            version: 'v2.7'
        });
    }
})();

