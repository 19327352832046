(function(){
	'use strict';
	
	angular
		.module('überliste')
		.component('dropdown', {
			templateUrl: 'template/component/dropdown.html',
			bindings: {
				value: '@',
				list: '<',
				disabled: '<',
				onItemSelect: '&'
			}
		});
})();
