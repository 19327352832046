(function () {
    'use strict';

    angular.module('überliste').service('LoginService', LoginService);

    LoginService.$inject = [
        '$http',
        '$httpParamSerializerJQLike',
        '$q',
        '$log',
        'Session',
        'ezfb',
        'UserService',
        'UserSettingsService',
    ];

    function LoginService(
        $http,
        $httpParamSerializerJQLike,
        $q,
        $log,
        session,
        ezfb,
        userService,
        userSettingsService
    ) {
        var config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
            },
        };

        return {
            isAuthenticated: isAuthenticated,
            login: login,
            socialLogin: socialLogin,
            logout: logout,
            gapiPromise: gapiPromise,
        };

        function isAuthenticated() {
            var userResult = $q.defer();
            var userSettingsResult = $q.defer();

            if (!session.email) {
                userService
                    .get()
                    .then(function (res) {
                        if (res.data && res.data.email) {
                            session.create(res.data.email);
                            return userSettingsService.get();
                        } else {
                            return $q.reject();
                        }
                    })
                    .then(function (res) {
                        session.update(res.data);
                        userResult.resolve();
                        userSettingsResult.resolve();
                    })
                    .catch(function () {
                        userResult.reject();
                        userSettingsResult.reject();
                    });
            } else {
                userResult.resolve();
                userSettingsResult.resolve();
            }
            return $q.all([userResult.promise, userSettingsResult.promise]).catch(function () {
                $log.log('Unable to get logged in status.');
                return $q.reject();
            });
        }

        function login(credentials) {
            return $http.post('/signin', $httpParamSerializerJQLike(credentials), config).then(function () {
                session.create(credentials.email);
                userSettingsService.get().then(function (res) {
                    session.update(res.data);
                });
                return credentials.email;
            });
        }

        function socialLogin(providerId) {
            if (providerId === 'facebook') {
                return fbLogin().then(function (token) {
                    var data = {
                        token: token,
                        language: userService.getUserLanguage(),
                    };
                    return $http.post('/signin/facebook', $httpParamSerializerJQLike(data), config);
                });
            } else {
                return $q.reject();
            }
        }

        function logout(success, error) {
            if (ezfb.$ready === true) {
                socialLogout();
            }
            if (window.gapi) {
                googleLogout(success, error);
            }
            $http.post('/signout').then(success, error);
        }
        function googleLogout(success, error) {
            var auth2;
            window.gapi.load('auth2', function () {
                auth2 = gapi.auth2.getAuthInstance();
                auth2.signOut().then(success, error);
            });
        }
        function socialLogout() {
            if (ezfb.getAuthResponse()) {
                ezfb.logout();
            }
        }

        /*
         * Facebook login
         */
        function fbLogin() {
            return ezfb
                .getLoginStatus()
                .then(function (response) {
                    if (response.status === 'connected') {
                        return $q.when(response);
                    } else {
                        return ezfb.login(angular.noop, { scope: 'email' });
                    }
                })
                .then(function (response) {
                    if (response.status === 'connected') {
                        return $q.when(response.authResponse.accessToken);
                    } else {
                        return $q.reject();
                    }
                })
                .catch(function (error) {
                    $log.error('ERROR', error);
                });
        }

        function gapiPromise() {
            var deferred = $.Deferred();
            if (gapi) {
                deferred.resolve(gapi);
            }
            return deferred.promise();
        }
    }
})();
