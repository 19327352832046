(function () {
    'use strict';

    angular
        .module('überliste')
        .service('ProductListItemService', ProductListItemService);

    ProductListItemService.$inject = ['$http', '$q'];

    function ProductListItemService($http, $q) {
        return {
            update: update,
            createProductListItem: createProductListItem
        };

        function update(item) {
            var url = '/api/v1/items/' + item.id;
            item.updated = Date.now();

            var deferred = $q.defer();
            $http.put(url, item).then(function (result) {
                deferred.resolve(result.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function createProductListItem(item) {
            return function(activeListId) {
                return {
                    productList: activeListId,
                    product: item.pmId || item.product.pmId,
                    state: 'NEW',
                    amount: item.amount ? item.amount : 1.0,
                    measurement: item.measurement ? item.measurement : null,
                    description: item.description ? item.description : '',
                    created: Date.now(),
                    updated: Date.now()
                };
            };
        }
    }
})();
