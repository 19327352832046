(function() {
    'use strict';

    angular
        .module('überliste')
        .directive('cancelBtn', cancelBtn);

    function cancelBtn() {
        return {
            restrict: 'E',
            templateUrl: 'template/directive/cancelBtn.html',
            scope: {
                cancel: '&' // FIXME: Not uses in directive!
            }
        };
    }
})();
