/* exported MeasurementsCatalog */
function MeasurementsCatalog(measurementsList) {
	'use strict';

    this.measurements = [];
    this.emptyMeasurement = {};

    this.processData = function (data) {
        var measurements = [];
        if (!data || data.constructor !== Array) {
            return;
        }

        this.emptyMeasurement = {
            comment: '',
            name: '–',
            plural: '–',
            shortcut: '&ndash;'
        };
        measurements.push(this.emptyMeasurement);

        data.forEach(function (measurement) {
            measurement.name = measurement.shortcut;
            measurements.push(measurement);
        });
        this.measurements = measurements;
    };

    this.processData(measurementsList);

    this.isEmptyMeasurement = function (measurement) {
        if (!measurement) {
            return true;
        }
        return measurement === this.emptyMeasurement;
    };

    this.getById = function (pmId) {
        var measurement = this.measurements.find(function (measurement) {
            return measurement.pmId === pmId;
        });
        return measurement ? measurement : this.emptyMeasurement;
    };
}
