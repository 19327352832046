(function() {
    'use strict';

    angular
        .module('überliste')
        .service('ListUtil', ListUtil);

    ListUtil.$inject = ['ProductListService'];

    function ListUtil(ProductListService) {
        var listUtil = this;

        listUtil.sync = function(oldList, newList, itemToId, updateItem, sortFn, insertFn) {
            var i = oldList.length;
            while(i--) {
                var oldItem = oldList[i];
                var idx = listUtil.getIndexOfItemWithProductPmId(newList, itemToId(oldItem));

                if(idx < 0) {
                    oldList.splice(i, 1);
                } else {
                    var newItem = newList[idx];
                    updateItem(oldItem, newItem);
                }
            }

            sortFn(oldList, newList);
            newList.forEach(function(newItem, newItemIndex) {
                insertFn(oldList, newItem, newItemIndex);
            });
        };

        // FIXME
        listUtil.getIndexOfItemWithProductPmId = ProductListService.getIndexOfItemWithProductPmId;
    }
})();