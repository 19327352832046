(function () {
    'use strict';

    angular
        .module('überliste')
        .service('NotificationService', NotificationService);

	NotificationService.$inject = ['$log'];

    function NotificationService($log) {
        var scope;

        return {
            resetNotification: resetNotification,
            setControllerScope: setControllerScope,
            showInfo: info,
            showSuccess: success,
            showError: error,
            showWarn: warn,
            addWatch: addWatch
        };

        function setControllerScope(controllerScope) {
            scope = controllerScope;
        }

        function info(msg) {
            show('alert-info', msg);
        }

        function success(msg) {
            show('alert-success', msg);
        }

        function error(msg, response) {
            show('alert-danger', msg, response);
        }

        function warn(msg, response) {
            show('alert-warning', msg, response);
        }

        function resetNotification() {
            scope.showNotification = false;
            scope.notificationMsg = '';
            scope.notificationClass = null;
        }

        function show(notifClass, notifMsg, response) {
            if (checkScope()) {
                resetNotification();
                scope.notificationClass = notifClass;
                scope.notificationMsg = notifMsg;
                if (response) {
                    $log.log(response);
                }
                scope.showNotification = true;
            }

        }

        function addWatch(parameter) {
            if (checkScope()) {
                scope.$watch(parameter, resetNotification);
            }
        }

        function checkScope() {
            if (!scope) {
                $log.error('Controller\'s scope is not defined. Please call function setControllerScope before.');
                return false;
            }
            return true;
        }

    }

})();
