(function () {
    'use strict';

    angular
        .module('überliste')
        .service('NavigationService', NavigationService);

    NavigationService.$inject = ['$location', '$window'];

    function NavigationService($location, $window) {
        var LOCATION_ALREADY_THERE = 0,
            LOCATION_SET = 1;

        return {
            navigateToShoppingList: navigateToShoppingList,
            navigateToPasswordForget: navigateToPasswordForget,
            navigateToHome: navigateToHome,
            navigateBack: navigateBack,
            navigateToAccountDeleted: navigateToAccountDeleted,
            // for testing purposes only
            _navigateTo: navigateTo
        };

        /**
         * Navigates to a new page through location path.
         *
         * @param path Path to navigate to.
         */
        function navigateTo(path) {
            if ($location.path() === path) {
                return LOCATION_ALREADY_THERE;
            } else {
                var sharingListToken = $location.search().join;
                if (sharingListToken) {
                    $location.path(path).search({ join: sharingListToken });
                } else {
                    $location.path(path).search({});
                }
                return LOCATION_SET;
            }
        }

        function navigateToShoppingList() {
            navigateTo('/shoppinglist');
        }

        function navigateToPasswordForget() {
            navigateTo('/account/forgotpassword');
        }

        function navigateToHome() {
            navigateTo('/');
        }

        function navigateBack(){
            $window.history.back();
        }

        function navigateToAccountDeleted() {
            navigateTo('/accountDeleted');
        }

    }
})();
