(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageHeader', ['$document', function ($document) {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageHeader.html',
                link: function (scope, element, attrs) {
                    if (attrs.hideOnTop) {
                        var description = document.querySelectorAll('#description');
                        $document.on('scroll', function () {
                            // Selector should be inside event handler, because the app re-creates header for the each page
                            var header = document.querySelector('.homepage-header');
                            if (header) {
                                // Show header after the first section
                                var descriptionTop = description && description.length > 0 ? description[0].getBoundingClientRect().top : 1;
                                header.style.visibility = descriptionTop <= 0 ? 'visible' : 'hidden';
                            }
                        });
                    } else {
                         document.querySelector('.homepage-header').style.visibility = 'visible';
                    }
                }
            };

            // FIXME: uses parent component scope!
        }]);
})();
