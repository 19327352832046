(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageFooter', function () {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageFooter.html'
            };
        });
})();
