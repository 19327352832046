(function() {
    'use strict';

    angular
        .module('überliste')
        .service('WebSocketService', WebSocketService);

    WebSocketService.$inject = ['$log', '$rootScope', '$websocket', 'Session', '$timeout'];

    function WebSocketService($log, $rootScope, $websocket, Session, $timeout) {
        var dataStream = null;

        this.closeWebSocket = function() {
            if(dataStream) {
                $log.info('Closing socket connection...');
                dataStream.close(true);
            }
        };

        this.connectWebSocket = function() {
            var self = this;
            if(dataStream) {
                $log.info('Closing old socket connection');
                dataStream.close(true);
            }

            $log.info('Connecting to web-socket...');
            dataStream = $websocket(
                url('/websocket'));
            
            dataStream.send(angular.toJson({
                token: Session.token,
                os: 'web',
                user: Session.email
            }));

            dataStream.onMessage(function (message) {
                if (message.data) {
                    var msg = angular.fromJson(message.data);

                    $log.log('Got WS notification: ', msg);

                    switch (msg.type) {
                        case 'CREATE':
                            onCreate(msg.subject);
                            break;
                        case 'UPDATE':
                            onUpdate(msg);
                            break;
                        case 'DELETE':
                            onDelete(msg);
                            break;
                        default:
                            $log.error('Unknown ws message type', msg.type);
                            break;
                    }
                }
            });

            dataStream.onOpen(function () { $log.info('Connected to web-socket'); });

            dataStream.onClose(function () {
                $rootScope.$emit('ws:onClose');
                $log.info('Closing web-socket');
                if(dataStream.readyState === 3){
                    $timeout(function(){
                        self.connectWebSocket();
                    },20000);
                }
            });

            dataStream.onError(function () {
                $log.error('Web-socket connection error');
                $rootScope.$emit('ws:onClose');
            });
        };

        function url(s) {
            var l = window.location;
            return ((l.protocol === 'https:') ? 'wss://' : 'ws://') + l.host + s;
        }

        function onCreate(subject) {
            switch (subject) {
                case 'PRODUCT':
                    $rootScope.$emit('ws:product:create');
                    break;
                case 'PRODUCT_LIST':
                    $rootScope.$emit('ws:productlist:create');
                    break;
                case 'PRODUCT_LIST_ITEM':
                    $rootScope.$emit('ws:productlistitem:create');
                    break;
            }
        }

        function onUpdate(msg) {
            switch (msg.subject) {
                case 'PRODUCT':
                    $rootScope.$emit('ws:product:update');
                    break;
                case 'PRODUCT_LIST':
                    $rootScope.$emit('ws:productlist:update', msg.data);
                    break;
                case 'PRODUCT_LIST_ITEM':
                    $rootScope.$emit('ws:productlistitem:update', msg.data);
                    break;
            }
        }

        function onDelete(msg) {
            switch (msg.subject) {
                case 'PRODUCT':
                    $rootScope.$emit('ws:product:delete', msg.data.pmId);
                    break;
                case 'PRODUCT_LIST':
                    $rootScope.$emit('ws:productlist:delete', msg.data.id);
                    break;
                case 'PURCHASE_HISTORY_ENTRY':
                    $rootScope.$emit('ws:historyentry:delete', msg.data);
                    break;
                default:
                    // triggers lists and history reload
                    $rootScope.$emit('ws:productlist:create');
            }
        }
    }
})();