(function(){
    'use strict';

    angular
        .module('überliste')
        .component('historyList', {
            templateUrl: 'template/component/historyList.html',
            controller: HistoryListController,
            bindings: {
                categoriesCatalog: '<',
                removedProduct: '<',
                itemToRemove: '<',
                insertListItem: '&',
                errorHandler: '&'
            }
        });

    HistoryListController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        'Session',
        'UserSettingsService',
        'ProductListService',
        'PurchaseHistoryEntryService'];

    function HistoryListController(
        $q,
        $scope,
        $rootScope,
        Session,
        UserSettingsService,
        ProductListService,
        PurchaseHistoryEntryService) {
            var ctrl = this;

            ctrl.historyList = [];
            ctrl.showHistory = false;
            ctrl.activeSorter = function() { return 0; };

            var waitForSorter = $q.defer();
            $scope.$on('activeSorter', function(event, arg) {
                event.stopPropagation();
                ctrl.activeSorter = arg.sort;
                waitForSorter.resolve();
            });

            var events = [
                { name: 'ws:productlist:create', unbind: angular.noop },
                { name: 'ws:productlistitem:create', unbind: angular.noop },
                { name: 'ws:product:update', unbind: angular.noop },
                { name: 'ws:productlist:update', unbind: angular.noop },
                { name: 'ws:productlistitem:update', unbind: angular.noop },
                { name: 'ws:historyentry:delete', unbind: angular.noop },
                { name: 'change:activelist', unbind: angular.noop }
            ];

            ctrl.$onDestroy = function() {
                angular.forEach(events, function(event) {
                    event.unbind();
                });
            };

            ctrl.$onInit = function() {
                // FIXME: Make sure, that session settings are there! (Should be after login...)
                ctrl.showHistory = Session.settings ? Session.settings.showHistory : false;
                loadPurchaseHistory();

                angular.forEach(events, function(event) {
                    if(event.name === 'ws:historyentry:delete') {
                        event.unbind = $rootScope.$on(event.name, function(event, data) {
                            removeHistoryItem(data);
                        });
                    } else {
                        event.unbind = $rootScope.$on(event.name, loadPurchaseHistory);
                    }
                });
            };

            ctrl.$onChanges = function(changes) {
                if(changes.categoriesCatalog &&
                    changes.categoriesCatalog.isFirstChange() &&
                    changes.removedProduct &&
                    changes.removedProduct.isFirstChange() &&
                    changes.itemToRemove &&
                    changes.itemToRemove.isFirstChange()) {
                    return; // $onChanges is called before $onInit so ignore the first change
                            // else loadPurchaseHistory is called twice
                }

                if((changes.categoriesCatalog || changes.removedProduct) && Session.mail !== null) {
                    loadPurchaseHistory();
                }

                if(changes.itemToRemove) {
                    removeHistoryItem({product: {pmId: changes.itemToRemove.currentValue.product} });
                }
            };

            function loadPurchaseHistory() {
                waitForSorter
                    .promise
                    .then(PurchaseHistoryEntryService.get)
                    .then(function (result) {
                        if (result.data && result.data.constructor === Array) {
                            syncPurchases(ctrl.historyList, result.data);
                        }
                    })
                    .catch(function (response) {
                        ctrl.errorHandler({
                            response: response,
                            message: 'alert.error_load_purchases'
                        });
                    });
            }

            function syncPurchases(purchaseHistory, purchaseHistoryFromServer) {
                for (var i = 0; i < purchaseHistory.length; i++) {
                    var idx = ProductListService.getIndexOfItemWithProductPmId(
                        purchaseHistoryFromServer,
                        purchaseHistory[i].product.pmId);

                    if (idx < 0) {
                        // 1. Remove
                        purchaseHistory.splice(i--, 1);
                    } else {
                        // 2. Update
                        purchaseHistory[i].product = purchaseHistoryFromServer[idx].product;
                        purchaseHistory[i].count = purchaseHistoryFromServer[idx].count;
                        purchaseHistoryFromServer.splice(idx, 1);
                    }
                }

                // 3. Create
                var sortedList = ctrl.activeSorter(purchaseHistory.concat(purchaseHistoryFromServer));

                purchaseHistoryFromServer.forEach(function (purchaseFromServer) {
                    var i = sortedList.indexOf(purchaseFromServer);
                    purchaseHistory.splice(i, 0, purchaseFromServer);

                });

                // 4. Update categories (colors)
                purchaseHistory.forEach(function (purchase) {
                    purchase.categoryColor = ctrl.categoriesCatalog.getColorById(purchase.product.category);
                });

                updateHistoryListItemOrders(sortedList);
            }

            ctrl.showHidePurchases = function() {
                ctrl.showHistory = !ctrl.showHistory;

                UserSettingsService
                    .update({showHistory: ctrl.showHistory})
                    .catch(function(response) {
                        ctrl.errorHandler({
                            response: response,
                            message: '' // FIXME
                        });
                    });
            };

            ctrl.addItemToProductList = function(historyItem) {
                historyItem.hidden = true;
                historyItem.amount = 1.0;

                ctrl.insertListItem({item: historyItem});
                removeHistoryItem(historyItem);

            };

            function removeHistoryItem(historyItem) {
                var idx = ProductListService.getIndexOfItemWithProductPmId(ctrl.historyList, historyItem.product.pmId);
                if (idx > -1) {
                    ctrl.historyList.splice(idx, 1);

                    var historyListCopy = ctrl.historyList.slice();
                    historyListCopy.sort(function (a, b) {
                        return a.order - b.order;
                    });
                    updateHistoryListItemOrders(historyListCopy);
                }
            }

            function updateHistoryListItemOrders(sortedHistoryList) {
                for (var i = 0; i < sortedHistoryList.length; i++) {
                    var j = ctrl.historyList.indexOf(sortedHistoryList[i]);
                    ctrl.historyList[j].order = i;
                    ctrl.historyList[j].opacity = getOpacity(i);
                    ctrl.historyList[j].hidden = false;
                }
            }

            /*
             * Calculation of history item opacity.
             */
            function getOpacity(order) {
                var minOpacity = 0.4;

                var historyListItemsSelector = angular.element(document.querySelector('.history-list-items'));
                var historyListItemSelector = angular.element(document.querySelector('.history-list-item'));

                var fullHeight = historyListItemsSelector.prop('offsetHeight');
                var itemHeight = historyListItemSelector.prop('offsetHeight') ?
                    historyListItemSelector.prop('offsetHeight') : 35;

                var totalPossibleElements = Math.abs(fullHeight / itemHeight);
                var totalVisibleElements =
                        totalPossibleElements > ctrl.historyList.length ?
                            ctrl.historyList :
                            totalPossibleElements;

                var opacityStep = (1.0 - minOpacity) / (totalVisibleElements / 2.0);

                var scrollPos = historyListItemsSelector.prop('scrollTop');

                // Order correction for scroll
                order -= Math.abs(scrollPos / itemHeight);

                // Opacity for the top and the bottom of the list
                // return order <= totalVisibleElements / 2 ? minOpacity + opacityStep * order : 2 - opacityStep * order;

                // Opacity for the bottom of the list
                return order < totalVisibleElements / 3 ? 1 : 1.5 - opacityStep * order;
            }

            ctrl.executeSort = function(sortFn) {
                ctrl.activeSorter = sortFn;
                var historyListCopy = ctrl.historyList.slice();
                var sortedHistoryList = sortFn(historyListCopy);
                updateHistoryListItemOrders(sortedHistoryList);
            };

            ctrl.setOpacity = function() {
                for (var i in ctrl.historyList) {
                    var purchase = ctrl.historyList[i];
                    purchase.opacity = getOpacity(purchase.order);
                }

                // force UI update
                $scope.$digest();
            };
    }
})();
