(function () {
    'use strict';

    angular
        .module('überliste')
        .service('BroadcastingService', BroadcastingService);

    BroadcastingService.$inject = ['$rootScope'];

    // watch rescans the whole scope in order to find changes
    // broadcast just sends notifications to its subscribed listeners
    function BroadcastingService($rootScope) {
        var BroadcastMessages = {
            updateSession: 'update session',
            categoriesArrived: 'categories arrived',
            listsChanged: 'lists changed',
            listItemChanged: 'list item changed',
            connectionLost: 'lost connection',
            logout: 'logout'
        };

        return {
            broadcastMessages : BroadcastMessages,
            sendBroadcastMessage: sendBroadcastMessage,
            addBroadcastListener: addBroadcastListener
        };

        function sendBroadcastMessage(message, args) {
            $rootScope.$broadcast(message, args);
        }

        function addBroadcastListener(scope, message, callback) {
            scope.$on(message, callback);
        }
    }
})();