(function () {
    'use strict';

    angular
        .module('überliste')
        .service('UserSettingsService', UserSettingsService);

    UserSettingsService.$inject = ['$http', '$q', '$rootScope'];

    function UserSettingsService($http, $q, $rootScope) {
        var url = '/api/v1/usersettings';
        var settings = {};
        var deferred = null;

        return {
            get: get,
            getSettings: getSettings,
            update: update,
            setActiveList: setActiveList
        };

        function get() {
            deferred = $q.defer();
            return $http
                .get(url)
                .then(function(response) {
                    settings = response.data;
                    deferred.resolve();
                    $rootScope.$emit('userSettingsChanged', settings);
                    return response;
                });
        }

        // FIXME: TEST!!!!!
        function getSettings() {
            if(!deferred) { get(); }
            return deferred.promise.then(function(){return settings;});
        }

        function update(userSettings) {
            return $http
                .put(url, userSettings)
                .then(function(response) {
                    settings = userSettings;
                    $rootScope.$emit('userSettingsChanged', settings);
                    return response;
                });

        }

        /**
         * Set active product list
         *
         * @param productList
         */
        function setActiveList(productList) {
            return $http
                .put(url + '/setactivelist', productList)
                .then(function(response) {
                    settings.activeProductListId = productList.id;
                    $rootScope.$emit('userSettingsChanged', settings);
                    return response;
                });
        }
    }
})();
