(function () {
    'use strict';

    angular
        .module('überliste')
        .service('MotivationPhraseService', MotivationPhraseService);

    MotivationPhraseService.$inject = ['$http'];

    function MotivationPhraseService($http) {
        return {
            getRandom: getRandom
        };

        function getRandom() {
            var url = '/api/v1/motivationphrases/random';

            return $http
            	.get(url)
            	.then(function (result) {
                    return result.data;
                });
        }
    }
})();
