(function(){
    'use strict';

    angular
        .module('überliste')
        .directive('okBtn', okBtn);

    function okBtn() {
        return {
            restrict: 'E',
            templateUrl: 'template/directive/okBtn.html',
            scope: {
                apply: '&'
            }
        };
    }
})();
