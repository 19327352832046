(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('UserSettingsController', UserSettingsController);

    UserSettingsController.$inject = ['$scope',
        '$translate',
        '$timeout',
        '$log',
        'Session',
        'UserService',
        'UserSettingsService',
        'NotificationService',
        'Session',
        'LoginService',
        'NavigationService'];

    var TRANSLATION_KEY_PREFIX = 'uss.language_';
    var SHOW_BOUGHT_PREFIX = 'uss.showBought_';

    var LANGUAGE_DE = 'de';
    var LANGUAGE_EN = 'en';
    var LANGUAGE_RU = 'ru';

    function UserSettingsController($scope,
                                    $translate,
                                    $timeout,
                                    $log,
                                    Session,
                                    userService,
                                    userSettingsService,
                                    notificationService,
                                    session,
                                    loginService,
                                    navigationService) {
        var userSettings = {};
        init();

        $scope.showPasswordFields = showPasswordFields;
        $scope.closePasswordFields = closePasswordFields;
        $scope.showPassword = false;
        $scope.showAccountDeleteFields = showAccountDeleteFields;
        $scope.closeAccountDeleteFields = closeAccountDeleteFields;
        $scope.showAccountDelete = false;
        $scope.showBoughtSettings = false;
        $scope.apply = apply;
        $scope.cancel = cancel;

        notificationService.setControllerScope($scope);
        notificationService.addWatch('currentPassword');
        notificationService.addWatch('newPassword');
        notificationService.addWatch('repeatPassword');
        notificationService.addWatch('currentLanguageText');

        function init() {
            $scope.userNewInfo = null;
            $scope.currentPassword = null;
            $scope.newPassword = null;
            $scope.repeatPassword = null;
            $scope.currentUserEmail = Session.email;

            userService.get().then(function (res) {
                $scope.currentLanguageText = getLanguageKey(res.data.language);
            });

            $scope.languages = [
                {'name': getLanguageKey(LANGUAGE_EN)},
                {'name': getLanguageKey(LANGUAGE_DE)},
                {'name': getLanguageKey(LANGUAGE_RU)}
            ];

            $scope.showBought = [
                {'name' : SHOW_BOUGHT_PREFIX + 'yes'},
                {'name' : SHOW_BOUGHT_PREFIX + 'no'}
            ];

            userSettingsService.getSettings().then(function(settings) {
                userSettings = settings;
                $scope.showBoughtValue =
                    settings.showBoughtItemsInTheList ?
                        $scope.showBought[0].name :
                        $scope.showBought[1].name;
            });
        }

        $scope.onLanguageChanged = function (language) {
            $scope.currentLanguageText = language.name;
        };

        $scope.onShowBoughtValueChanged = function(value){
            $scope.showBoughtValue = value.name;
            userSettings.showBoughtItemsInTheList = value.name === $scope.showBought[0].name;
        };

        var error = function (response) {
            notificationService.showError('uss.settings_changed_error', response);
        };

        var success = function () {
            $scope.closeThisDialog();
        };

        function getLanguageKey(language) {
            return TRANSLATION_KEY_PREFIX + language;
        }

        function getLanguage(key) {
            return key.replace(TRANSLATION_KEY_PREFIX, '');
        }

        function showPasswordFields() {
            $scope.showPassword = true;
        }

        function closePasswordFields() {
            $scope.showPassword = false;
        }

        function showAccountDeleteFields(){
            $scope.showAccountDelete = true;
        }

        function closeAccountDeleteFields(){
            $scope.showAccountDelete = false;
        }

        function apply() {
            // language
            var language = getLanguage($scope.currentLanguageText);
            var user = {
                email: $scope.currentUserEmail,
                firstName: '',
                lastName: '',
                language: language
            };

            userSettings.language = language;

            userService.update(user).then(function () {
                $translate.use(language);
                // password
                if ($scope.showPassword && ($scope.currentPassword || $scope.newPassword)) {
                    changePassword();
                    userSettingsService.update(userSettings).then(success).catch(error);
                } else if ($scope.showAccountDelete && $scope.currentPassword) {
                    userService.deleteUser($scope.currentPassword, function () {
                        success();
                        session.destroy();
                        loginService.logout(navigationService.navigateToAccountDeleted(), function () {
                            $log.log('Logout failed.');
                        });
                    }, function () {
                        notificationService.showError('uss.incorrect_password_msg');
                        $scope.passwordValidationError = true;
                    });
                } else {
                    success();
                    userSettingsService.update(userSettings).then(success).catch(error);
                }
            }, error);
        }

        function changePassword() {
            if ($scope.newPassword === $scope.repeatPassword) {
                if (angular.isUndefined($scope.newPassword) || $scope.newPassword === '') {
                    notificationService.showError('uss.password_is_empty');
                    $scope.passwordValidationError = false;
                    $scope.newPasswordValidationError = true;
                } else {
                    userService.changePassword($scope.currentPassword, $scope.newPassword, success, function() {
                        notificationService.showError('uss.incorrect_password_msg');
                        $scope.passwordValidationError = true;
                        $scope.newPasswordValidationError = false;
                    });
                }
            } else {
                notificationService.showError('uss.password_doesnt_match_msg');
            }
        }

        $scope.$watch('currentPassword', function () {
            $scope.passwordValidationError = false;
        });

        $scope.$watch('newPassword', function (newValue) {
            $scope.newPasswordValidationError = false;
            $scope.repeatPasswordValidationError = $scope.repeatPassword ? newValue !== $scope.repeatPassword : false;
        });

        $scope.$watch('repeatPassword', function (newValue) {
            $scope.repeatPasswordValidationError = $scope.newPassword !== newValue;
        });

        function cancel() {
            $scope.closeThisDialog();
        }

    }
})();
