(function() {
    'use strict';
    angular
        .module('überliste')
        .component('historyListItem', {
            templateUrl: 'template/component/historyListItem.html',
            controller: HistoryListItemController,
            bindings: {
                historyListItem: '<',
                addItemToProductList: '&'
            }
        });

    HistoryListItemController.$inject = ['DialogService'];

    function HistoryListItemController(DialogService) {
        var ctrl = this;

        ctrl.addProductListItem = function() {
            ctrl.addItemToProductList({historyItem: ctrl.historyListItem});
        };

        ctrl.deleteHistoryListItem = function() {
            DialogService.openDeleteHistoryItemDialog(ctrl.historyListItem);
        };
    }

})();