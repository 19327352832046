/* global MeasurementsCatalog, CategoriesCatalog */
(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('ShoppingListController', ShoppingListController);

    ShoppingListController.$inject = [
        '$location',
        '$log',
        '$q',
        '$scope',
        '$timeout',
        '$rootScope',
        '$routeParams',
        'UserSettingsService',
        'ProductListService',
        'DialogService',
        'Session',
        'MeasurementService',
        'ProductCategoryService',
        'Measurements',
        'Categories',
        'WebSocketService'];

    function ShoppingListController(
        $location,
        $log,
        $q,
        $scope,
        $timeout,
        $rootScope,
    	$routeParams,
    	userSettingsService,
        productListService,
        dialogService,
        session,
        MeasurementService,
        ProductCategoryService,
        Measurements,
        Categories,
        WebSocketService) {

            var unbindTranslate = $rootScope.$on('$translateChangeSuccess', reloadMeasurementsAndCategories);
            var unbindOpen = $rootScope.$on('ngDialog.opened', dialogOpened);
            var unbindClose = $rootScope.$on('ngDialog.closing', dialogClosing);

            $scope.$on('$destroy', function(){
                unbindTranslate();
                unbindClose();
                unbindOpen();
                WebSocketService.closeWebSocket();
            });

            /*
             * Listen to events fired by ngDialog to toggle the blurry state
             * of the page.
             */
            $scope.dialogState = { opened: false };

            function dialogOpened() {
                $scope.dialogState = { opened: true };
            }

            function dialogClosing() {
                $timeout(function(){
                    $scope.dialogState = { opened: false };
                }, 0); // We need this here or else the watcher of ng-class does not fire
            }

            $scope.getDialogState = function() { return $scope.dialogState.opened; };

            /*
             * insert history list item into active list.
             */
            $scope.itemToInsert = {};
            $scope.insertListItem = function(item) {
                $scope.itemToInsert = item;
            };

            $scope.removedProduct = {};
            $scope.removeProduct = function(product) {
                $scope.removedProduct = product;
            };

            /*
             * remove history item from history list
             */
            $scope.historyItemToRemove = {};
            $scope.removeHistoryItem = function(item) {
                $scope.historyItemToRemove = item;
            };

            /*
             * Initialization
             */
            function $onInit() {
                if (! session.email) {
                    loginExpired();
                    return;
                } else {
                    $scope.$watch(
                        function() { return session.email; },
                        function(newVal) { $scope.currentUserEmail = newVal; },
                        true);
                }

                if ($routeParams.join) {
                    // Join a shared product list
                    joinProductList($routeParams.join);
                }

                userSettingsService.getSettings();
                WebSocketService.connectWebSocket();

                $scope.measurementsCatalog = new MeasurementsCatalog(Measurements);
                $scope.categoriesCatalog = new CategoriesCatalog(Categories, $scope.measurementsCatalog);
            }

            $onInit();

            /*
             * Redirect to the homepage and show a notification
             */
            function loginExpired() {
                $location.path('/loginexpired');
            }

            /*
             * Join a shared product list
             */
            function joinProductList(token) {
                productListService.join(token).then(function () {
                    $location.path('/shoppinglist').search(''); // search removes token from the URL
                    $rootScope.$emit('joinList');
                }).catch(function (response) {
                    $scope.error(response, 'alert.error_join_product_list');
                });
            }

            function reloadMeasurementsAndCategories() {
                $q.all([
                    MeasurementService.get(),
                    ProductCategoryService.get()
                ]).then(function(responses) {
                    $scope.measurementsCatalog = new MeasurementsCatalog(responses[0]);
                    $scope.categoriesCatalog = new CategoriesCatalog(responses[1], $scope.measurementsCatalog);
                });
            }

            $scope.error = function(response, errorMessage) {
                if(response.status !== 401) { // interceptor already handles this
                    var dialog = dialogService.openAlertDialog(errorMessage);
                    dialog.closePromise.then(function () {
                        $location.search('');
                    });
                }

                $log.error('Error: ', response);
            };
    }
})();
