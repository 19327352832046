(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('EditProductListItemController', EditProductListItemController);

    function EditProductListItemController($scope, categoriesCatalog, measurement, dialogTitle, draftListItem) {
        var ctrl = this;

        ctrl.dialogTitle = dialogTitle;
        ctrl.categoriesCatalog = categoriesCatalog;
        ctrl.measurement = measurement;
        ctrl.draftListItem = draftListItem;
        ctrl.apply = function apply() {
            $scope.closeThisDialog(draftListItem);
        };
        ctrl.cancel = $scope.closeThisDialog;

        ctrl.onMeasurementSelect = function (measurement) {
            ctrl.measurement = measurement;
            draftListItem.measurement = measurement.pmId; // REST parameter
            draftListItem.measurementPmId = measurement.pmId; // UI parameter
        };

        ctrl.select = function (category) {
            draftListItem.category = category; // REST parameter
            draftListItem.categoryPmId = category.pmId; // UI parameter
            draftListItem.categoryName = category.name; // UI parameter
            draftListItem.upperCategory = category; // UI parameter
            draftListItem.defaultColor = ctrl.categoriesCatalog.getColorById(category.pmId); // UI parameter
        };
    }
})();
