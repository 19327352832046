(function(){
    'use strict';

    angular
        .module('überliste')
        .directive('pmAlert', pmAlert);

    function pmAlert() {
        return {
            restrict: 'E',
            templateUrl: 'template/directive/pmAlert.html',
            scope: {
                type: '@'
            }
        };
    }
})();
