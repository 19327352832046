/* exported CategoriesCatalog */
function CategoriesCatalog(categoriesList, measurementsCatalog) {
	'use strict';

    var upperCategoryIds = [100000, 200000, 300000, 400000, 500000, 600000, 700000];

    this.categories = [];
    this.defaultCategory = {};
    this.upperCategories = [];
    this.measurementsCatalog = measurementsCatalog;

    function getImgId (pmId) {
        return pmId / 100000;
    }

    function getIconById (pmId) {
        return 'category_' + getImgId(pmId) + '_icon_medium.png';
    }

    this.processData = function (data) {
        var categories = [];
        var upperCategories = [];
        if (!data || data.constructor !== Array) {
            return;
        }
        data.forEach(function (category) {
            category.icon = getIconById(category.pmId);

            categories.push(category);
            if (upperCategoryIds.indexOf(category.pmId) > -1) {
                upperCategories.push(category);
            }
        });
        this.categories = categories;
        this.upperCategories = upperCategories;
        this.defaultCategory = this.upperCategories[0];
    };

    this.processData(categoriesList);

    this.getIconById = getIconById;

    this.getColorById = function (pmId) {
        var category = this.getById(pmId);
        return category ? category.defaultColor : 'transparent';
    };

    this.getById = function(pmId) {
        if (!this.categories || this.categories === []) {
            return null;
        }

        return findMatchingCategoryById(this.categories, pmId);

        /*
        find is EcmaScript 6 and not available in Internet Explorer!
        https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find

        return this.categories.find(function (category) {
            return category.pmId === pmId;
        });*/

    };

    function findMatchingCategoryById(categories, pmId) {
        var category = null;

        for (var i = 0; i < categories.length; i++) {
            var c = categories[i];
            if(c.pmId === pmId) {
                category = c;
                break;
            }
        }

        return category;
    }

    this.getUpperCategoryById = function (pmId) {
        var category = this.getById(pmId);
        return this.getUpperCategory(category);
    };

    this.getUpperCategory = function (category) {
        var upperCategory = category;
        if (!upperCategory) {
            return null;
        }
        while (upperCategory.parentCategory) {
            upperCategory = this.getById(upperCategory.parentCategory);
            if (!upperCategory) {
                return null;
            }
        }
        return upperCategory;
    };

    this.getMeasurements = function (category) {
        function containsMeasurement(a, obj) {
            return a.some(function(e) { return e.shortcut === obj.shortcut; });
        }

        // get category from catalog to ensure the correct translation
        //category = this.getById(category.pmId);
        var measurements = !category || category.measurements.length === 0 ? measurementsCatalog.measurements : category.measurements;
        if (!containsMeasurement(measurements, measurementsCatalog.emptyMeasurement)) {
            measurements.unshift(measurementsCatalog.emptyMeasurement);
        }

        // name field is necessary for the dropdown component
        measurements.forEach(function(measurement) {
            measurement.name = measurement.shortcut;
        });

        return measurements;
    };

}

