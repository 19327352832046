(function () {
    'use strict';

    angular
        .module('überliste')
        .component('productList', {
            templateUrl: 'template/component/productList.html',
            controller: ProductListController,
            bindings: {
                onDeleteProduct: '&',
                onAddProduct: '&',
                errorHandler: '&',
                measurementsCatalog: '<',
                categoriesCatalog: '<',
                itemToInsert: '<' // push trigger for when the history list wants to insert a new item
            }
        });

    ProductListController.$inject = [
        '$scope',
        '$rootScope',
        'MotivationPhraseService',
        'ProductListService',
        'ProductListItemService',
        'UserSettingsService',
        'ListUtil',
        'Session'];

    function ProductListController(
        $scope,
        $rootScope,
        MotivationPhraseService,
        ProductListService,
        ProductListItemService,
        UserSettingsService,
        ListUtil,
        Session) {

            var ctrl = this;
            ctrl.activeList = {};
            ctrl.motivationPhrase = '';

            ctrl.errorHandlerForChildren = function(response, message) {
                // FIMXE find out how to pass a function binding through component tree
                ctrl.errorHandler({ response: response, message: message });
            };

            var unbindProductCreate = $rootScope.$on('ws:product:create', function() {
                loadProductListItemsFromServer(ctrl.activeList);
            });

            var unbindProductListUpdate = $rootScope.$on('ws:productlist:update',
                function(event, data) {
                    if (data.id === ctrl.activeList.id) {
                        // active list was updated, reload
                        loadProductListItemsFromServer(ctrl.activeList);
                    }
                });

            var unbindProductListItemUpdate = $rootScope.$on('ws:productlistitem:update',
                function(event, data) {
                    var found = false;
                    for (var i = 0; ctrl.activeList.items.length; i++) {
                        var item = ctrl.activeList.items[i];
                        if(item && item.id && data && data.id){
                            if (item.id === data.id && item.state.toUpperCase() === 'NEW') {
                                item.state = data.state;
                                item.amount = data.amount;
                                item.measurementPmId = data.measurement;
                                item.description = data.description;
        
                                found = true;
                                break;
                            }
                        }
                    }

                    if (!found) {
                        loadProductListItemsFromServer($scope.activeList);
                    }
                });

            var unbindDeleteProduct = $rootScope.$on('ws:product:delete',
                function(event, pmId) { deleteProduct(pmId); });

            ctrl.$onDestroy = function() {
                unbindProductCreate();
                unbindProductListUpdate();
                unbindProductListItemUpdate();
                unbindDeleteProduct();
            };

            $scope.$watchCollection('$ctrl.activeList.items', function (items) {
                updateItemsCount(items);
            });

            ctrl.$onChanges = function(changes) {
                if(changes.itemToInsert && Session.mail !== null) {
                    var newItem = changes.itemToInsert.currentValue;
                    if(! (!newItem || angular.equals(newItem, {}))) {
                        ctrl.insertListItem(newItem);
                    }
                }

                // only watch measurements changes because they are displayed
                // on the active list items
                if(changes.measurementsCatalog && Session.mail !== null) {
                    loadProductListItemsFromServer(ctrl.activeList);
                }
            };

            ctrl.setActiveList = function(list) {
                UserSettingsService
                    .setActiveList(list)
                    .then(function() {
                        ctrl.activeList = list;
                        $rootScope.$emit('change:activelist');
                        loadProductListItemsFromServer(list);
                    });
            };

            function loadProductListItemsFromServer(productList) {
                if (Object.keys(productList).length > 0 && productList.constructor === Object) {
                    ProductListService
                        .getProductListItems(productList)
                        .then(syncProductItems)
                        .catch(function (response) {
                            ctrl.errorHandler({
                                response: response,
                                message: 'alert.error_load_product_list'
                            });
                        });
                }
            }

            ctrl.updateActiveListName = function(newName) {
                ctrl.activeList.name = newName;

                ProductListService
                    .update(ctrl.activeList)
                    .catch(function (response) {
                        ctrl.errorHandler({
                            response: response,
                            message: 'alert.error_update_active_list'
                        });
                    });
            };

            ctrl.insertListItem = function (newItem) {
                var tmpList = {};
                angular.copy(ctrl.activeList, tmpList);

                var itemObj = ProductListItemService.createProductListItem(newItem)(ctrl.activeList.id);
                tmpList.items = [itemObj];
                ProductListService
                    .sync(tmpList, true)
                    .then(function(response){
                        ctrl.onAddProduct({product: itemObj});
                        return response;
                    })
                    .then(syncProductItems)
                    .catch(function (response) {
                        ctrl.errorHandler({
                            response: response,
                            message: 'alert.error_insert_item'
                        });
                    });
            };

            function syncProductItems(serverItems) {
                var localItems = ctrl.activeList.items;

                ListUtil.sync(
                    localItems,
                    serverItems,
                    itemToId,
                    updateItem,
                    sortFn,
                    insertFn);
            }

            function itemToId(localItem) {
                return localItem.productPmId;
            }

            function updateItem(localItem, serverItem) {
                angular.copy(serverItem, localItem);
            }

            function sortFn(localList, serverList) {
                localList.forEach(function (localItem, index) {
                    var idx = ListUtil.getIndexOfItemWithProductPmId(serverList, localItem.productPmId);
                    // state BOUGHT -> NEW
                    if (index > idx) {
                        swap(localList, index, idx);
                    }
                    serverList[idx].processed = true;
                });
            }

            function insertFn(localList, newItem, newItemIndex) {
                if (!newItem.processed) {
                    localList.splice(newItemIndex, 0, newItem);
                }
            }

            function swap(array, oldIndex, newIndex) {
                // FIXME: this can never happen
                if (newIndex >= array.length) {
                    var k = newIndex - array.length;
                    while ((k--) + 1) {
                        array.push(undefined);
                    }
                }

                var tmp = array[newIndex];
                array[newIndex] = array[oldIndex];
                array[oldIndex] = tmp;
            }

            ctrl.removeListItem = function(item) {
                if(item.state === 'BOUGHT') {
                    ctrl.onDeleteProduct({product: item});
                } else if(item.state === 'DELETED') {
                    deleteProduct(item.productPmId);
                }
            };

            function deleteProduct(pmId) {
                var itemIndex = ctrl.activeList.items.findIndex(function (item) {
                    return item.productPmId === pmId;
                });
                var removedItem = ctrl.activeList.items.splice(itemIndex, 1);
                updateItemsCount(ctrl.activeList.items);
                ctrl.onDeleteProduct({product: removedItem[0]});
            }

        // Updating number of items in list selection. Items count is another variable than items because changing of items causes UI animation
        function updateItemsCount(items) {
            if (Object.keys(ctrl.activeList).length > 0 && ctrl.activeList.constructor === Object) {
                ctrl.activeList.itemsCount =
                    angular.isUndefined(items) ?
                        0 :
                        items.filter(function (el) {
                            return el.state.toUpperCase() === 'NEW';
                        }).length;
            }

            showMotivationPhrase();
        }

        function showMotivationPhrase() {
            ctrl.motivationPhrase = '';
            if (ctrl.activeList.itemsCount <= 1) {
                return MotivationPhraseService
                    .getRandom()
                    .then(function (data) {
                        if (data) {
                            ctrl.motivationPhrase = data.phrase;
                        }
                });
            }
        }
    }

})();
