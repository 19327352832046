(function () {
    'use strict';

    angular
        .module('überliste')
        .service('ProductService', ProductService);

    ProductService.$inject = ['$http'];

    function ProductService($http) {
        var baseUri = '/api/v1/products';

        return {
            search: search,
            getUserDefined: getUserDefined,
            getOwn: getOwn,
            getById: getById,
            create: create,
            update: update,
            delete: delete_
        };

        function search(searchTerm) {
            var url = baseUri + '/search/?searchTerm=' + encodeURI(searchTerm);

            return $http
            	.get(url)
            	.then(function (result) {
            	    return result.data;
            	});
        }

        function getUserDefined(success, error) {
            var url = baseUri + '/user-defined';
            $http.get(url).then(success, error);
        }

        function getOwn(success, error) {
            var url = baseUri + '/own';
            $http.get(url).then(success, error);
        }

        function getById(id, success, error) {
            var url = baseUri + '/' + id;
            $http.get(url).then(success, error);
        }

        function create(product) {
            return $http.post(baseUri, product);
        }

        function update(product) {
            var url = baseUri + '/' + product.pmId;
            return $http.put(url, product);
        }

        function delete_(product, success, error) {
            var url = baseUri + '/' + product.pmId;
            $http.delete(url).then(success, error);
        }

    }
})();
