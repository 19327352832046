(function() {
    'use strict';

    angular
        .module('überliste')
        .directive('focusOn', focusOn);

    focusOn.$inject = ['$parse', '$timeout'];

    function focusOn($parse, $timeout) {
        return function(scope, element, attrs) {
            var model = $parse(attrs.focusOn);
            scope.$watch(model, function(value) {
                if(value === true) {
                    $timeout(function() {
                        element[0].focus();
                    }, 100);
                }
            });
        };
    }
})();
