(function() {
    'use strict';

    angular
        .module('überliste')
        .directive('scroll', scrollFn);

    function scrollFn() {
        return {
            restrict: 'A',
            scope: {
                scroll: '&'
            },
            link: function(scope, element) {
                element.bind('scroll', function () {
                    scope.scroll();
                });
            }
        };
    }
})();
