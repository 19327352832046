(function () {
    'use strict';

    angular
        .module('überliste')
        .controller('DeleteProductListController', DeleteProductListController);

    DeleteProductListController.$inject = ['$scope', '$log', 'ProductListService'];

    function DeleteProductListController($scope, $log, productListService) {
        $scope.confirmDelete = confirmDelete;
        $scope.cancel = cancel;

        function confirmDelete() {
            productListService.delete($scope.listToDelete, $scope.closeThisDialog, function (response) {
                $log.error('Error: productlist ' + $scope.listToDelete + ' couldn\'t be deleted.', response);
            });
        }

        function cancel() {
            $scope.closeThisDialog();
        }

    }
})();
