(function () {
    'use strict';
    angular
        .module('überliste')
        .directive('homepageIdea', function () {
            return {
                restrict: 'E',
                templateUrl: 'template/directive/homepageIdea.html'
            };
        });
})();
