(function() {
    'use strict';

    angular
        .module('überliste')
        .config(securityConfig);

    securityConfig.$inject = ['$httpProvider'];

    function securityConfig($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
    }
})();
